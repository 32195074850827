export const codeListsConfig = [
	{
		uri: "/cl/INN.CL.Request.Status",
		name: "requestStatus"
	},
	{
		uri: "/cl/INN.CL.ActivityType?level_flag=CD3",
		name: "activityType"
	},
	{
		uri: "/cl/INN.CL.CallType",
		name: "callType"
	},
	{
		uri: "/cl/Entity.CL.LegalForm",
		name: "legolForm"
	},
	{
		uri: "/cl/Entity.CL.Address.Region",
		name: "region"
	},
	{
		uri: "/cl/Entity.CL.Country",
		name: "country"
	},
	{
		uri: "/cl/Entity.CL.Address.City",
		name: "city"
	},
	{
		uri: "/cl/Entity.CL.AddressType",
		name: "addressType"
	},
	{
		uri: "/cl/Entity.CL.LegalForm",
		name: "legalForm"
	},
	{
		uri: "/cl/Entity.CL.CompanyType",
		name: "companyType"
	},
	{
		uri: "/cl/INN.CL.Entity.CompanySize",
		name: "companySize"
	}
]
