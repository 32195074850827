"use strict"

import {observable, action} from "mobx"

import {getFilters} from "../../global/helpers/api"
import {getUserCompanyInfo} from "../../global/helpers/actions"
import api from "../../global/actions/api"

import GlobalStore from "../../global/store/GlobalStore"

class RequestStore {
	@observable data = []

	@action getRequests() {
		GlobalStore.loading(true, "Načítam...")

		let req = getFilters([`company_id=${getUserCompanyInfo().id}`])
		// req["filters"].push({
		// 	associated_column: "type_id",
		// 	predicate: "=",
		// 	values: [
		// 		{id_value: "INN.Data.InnovationRequest"},
		// 		{id_value: "INN.Data.Request.IPSK121"},
		// 		{id_value: "INN.Data.Request.IPZS122"},
		// 		{id_value: "INN.Data.Request.IPZS2123"},
		// 		{id_value: "INN.Data.Request.IPSK2122"},
		// 		{id_value: "INN.Data.Request.IPSK3122"},
		// 		{id_value: "INN.Data.Request.ZSK123"}
		// 	]
		// })
		req["orders"] = {associated_column: "sent_date_time", asc: true}

		api
			.getRequests(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows)) {
					this.data = res.rows
				} else {
					this.data = []
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RequestStore()
export default singleton
