import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import {Container, Box, Grid, Typography, Link} from "@material-ui/core"
import CallDetailStore from "./callDetailStore"
import moment from "moment"
import XsButton from "../../global/ui/xsButton/xsButton"
import RouterStore from "../../global/store/RouterStore"
import RequestFormStore from "../RequestForm/RequestFormStore"
import renderHTML from "react-render-html"
import ReactTooltip from "react-tooltip"
import {getUserCallRestriction} from "../../global/helpers/actions"

function callDetail(props) {
	useEffect(() => {
		CallDetailStore.loadCallInfo(props.id)

		return () => {
			CallDetailStore.restrictionBA = ""
		}
	}, [])

	const callData = CallDetailStore.data || {}

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							{callData.CallTypeNameExt}
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Typography variant="h5">{callData.CallTypeCode}</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				{getUserCallRestriction().includes(callData.CallTypeCode) && (
					<Grid item>
						<Typography variant="h6" className="redDanger">
							Žiadateľ už má preplatenú Inovačnú poukážku, preto nie je možné podať novú žiadosť (ŽoIP) v rámci Výzvy.
						</Typography>
					</Grid>
				)}
				{/* {isNotEmpty(callData.InitialOpenFrom) || isNotEmpty(callData.InitialOpenTo) ? ( */}
				<React.Fragment>
					<Grid item>
						<Typography variant="body2">
							<b>Dátum vyhlásenia Výzvy:</b> {/* OpenSequence > 1   callData.InitialOpenFro : From*/}
							{callData.OpenSequence == 1
								? isSafe(callData.From)
									? moment(callData.From, "").format("DD.MM.YYYY HH:mm")
									: ""
								: isSafe(callData.InitialOpenFrom)
								? moment(callData.InitialOpenFrom, "").format("DD.MM.YYYY HH:mm")
								: ""}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body2">
							<b>Dátum uzavretia Výzvy:</b>{" "}
							{/* {isSafe(callData.InitialOpenTo) ? moment(callData.InitialOpenTo, "").format("DD.MM.YYYY HH:mm") : ""} */}
							{callData.OpenSequence == 1
								? isSafe(callData.To)
									? moment(callData.To, "").format("DD.MM.YYYY HH:mm")
									: ""
								: isSafe(callData.InitialOpenTo)
								? moment(callData.InitialOpenTo, "").format("DD.MM.YYYY HH:mm")
								: ""}
						</Typography>
					</Grid>
					<br />
					{callData.OpenSequence != 1 && (
						<React.Fragment>
							<Grid item>
								<Typography variant="body2">
									<b>Dátum {callData.OpenSequence}. vyhlásenia Výzvy:</b>{" "}
									{isSafe(callData.From) ? moment(callData.From, "").format("DD.MM.YYYY HH:mm") : ""}
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="body2">
									<b>Dátum {callData.OpenSequence}. uzavretia Výzvy:</b>{" "}
									{isSafe(callData.To) ? moment(callData.To, "").format("DD.MM.YYYY HH:mm") : ""}
								</Typography>
							</Grid>
						</React.Fragment>
					)}
				</React.Fragment>
				{callData.CallTypeCode == "IP_SK_121" &&
					isNotEmpty(CallDetailStore.restrictionBA) &&
					CallDetailStore.restrictionBA}
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">
						<b>Základné informácie o Výzve</b>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">{renderHTML(`<span> ${callData.BasicInformation} </span>`)} </Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				{/* <Grid item>
					<Typography variant="body2">
						<b>Upozornenie</b>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">{callData.Caution}</Typography>
				</Grid> */}
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">
						<b>Dokumentáciu k Výzve nájdete na odkaze</b>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						<Link
							// className="white pointer"
							onClick={() => window.open("https://www.siea.sk/inovacie/inovacnepoukazky/ ", "_blank")}
						>
							www.siea.sk/inovacie/inovacnepoukazky
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item xs={12}>
					<span data-tip data-for="butonDisabled">
						<XsButton
							className={"mr-2"}
							disabled={
								moment().isAfter(moment(callData.To, "")) ||
								callData.CallTypeCode == "IP_BBSK_121" ||
								getUserCallRestriction().includes(callData.CallTypeCode)
							}
							onClick={() => {
								delayedCallback(300, () => {
									RequestFormStore.callId = callData.ID
									RequestFormStore.callCode = callData.CallTypeCode
									RouterStore.push(`/request`)
								})
							}}
							color="primary"
							text={"VYPLNIŤ ŽIADOSŤ"}
						/>
					</span>
					{callData.CallTypeCode == "IP_BBSK_121" && (
						<ReactTooltip id="butonDisabled">
							Príjem žiadostí o inovačné poukážky v tejto Výzve je dočasne pozastavený pre vysoký počet spracovávaných
							žiadostí a dostupnú alokáciu finančných zdrojov.
						</ReactTooltip>
					)}
					<XsButton
						onClick={() => {
							delayedCallback(300, () => {
								RouterStore.push(`/calls`)
							})
						}}
						color="primary"
						text={"ZRUŠIŤ"}
					/>
				</Grid>
			</Grid>
		</Container>
	)
}

export default injectIntl(observer(callDetail))

