import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import moment from "moment"
import CallsListStore from "./callsListStore"
import {Container, Box, Grid, Typography} from "@material-ui/core"
import XsTable from "../../global/ui/xsTable/xsTable"
import RouterStore from "../../global/store/RouterStore"

function callsList() {
	const columns = [
		{
			Header: "Dátum vyhlásenia Výzvy",
			width: 150,
			accessor: "From",
			sortType: (rowA, rowB) => {
				const created_at_A = rowA.original.From
				const created_at_B = rowB.original.From
				if (created_at_A > created_at_B) return -1
				if (created_at_B > created_at_A) return 1
				return 0
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		{
			Header: "Dátum uzavretia Výzvy",
			width: 150,
			accessor: "To",
			sortType: (rowA, rowB) => {
				const send_date_time_A = isNotEmpty(rowA.original.To) ? rowA.original.To : ""
				const send_date_time_B = isNotEmpty(rowB.original.To) ? rowB.original.To : ""
				if (send_date_time_A > send_date_time_B) return -1
				if (send_date_time_B > send_date_time_A) return 1
				return 0
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		{
			Header: "Kód Výzvy",
			width: 120,
			accessor: "CallTypeCode"
		},
		{
			Header: "Názov Výzvy",
			width: 200,
			accessor: "CallTypeNameExt",
			sortType: "basic"
		},
		{
			Header: "Stav Výzvy",
			width: 150,
			accessor: "State",
			sortType: "basic"
		}
	]

	useEffect(() => {
		CallsListStore.loadListData()
	}, [])

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							Zoznam Výziev
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Typography variant="body2">Kliknutím na Výzvu si pozriete detaily Výzvy</Typography>
				</Grid>
			</Grid>
			<Box pt={2}>
				<Grid container>
					<Grid item xs={12}>
						<Box pt={1}>
							<XsTable
								columns={columns}
								data={CallsListStore.listData}
								onRowClick={(row) => RouterStore.push(`/call?id=${row.ID}`)}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default injectIntl(observer(callsList))

