import {action} from "mobx"
import {printReport} from "../../global/helpers/actions"
import api from "../../global/actions/api"
import GlobalStore from "../../global/store/GlobalStore"
import moment from "moment"

class RequestPrintStore {
	@action print(form) {
		const formVals = form.values()

		let pravnickaOsoba = ""
		if (isNotEmpty(formVals.companyDesc)) {
			let type = GlobalStore.CL["companyType"].find((row) => row._id == formVals.companyDesc)
			if (isSafe(type)) {
				pravnickaOsoba = type.name_ext
			}
		}

		let adresaObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCity)
			if (isSafe(address)) {
				adresaObec = address.name_ext
			}
		}

		let adresaKraj = ""
		if (isNotEmpty(formVals.companyRegion)) {
			let address = GlobalStore.CL["region"].find((row) => row._id == formVals.companyRegion)
			if (isSafe(address)) {
				adresaKraj = address.name_ext
			}
		}

		let adresaCorspondingObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCorrespondenceCity)
			if (isSafe(address)) {
				adresaCorspondingObec = address.name_ext
			}
		}

		let pravnaForma = ""
		if (isNotEmpty(formVals.companyLegalForm)) {
			let forma = GlobalStore.CL["legalForm"].find((row) => row._id == formVals.companyLegalForm)
			if (isSafe(forma)) {
				pravnaForma = forma.name_ext
			}
		}

		let companySize = ""
		if (isNotEmpty(formVals.companySize)) {
			let codeListObj = GlobalStore.CL["companySize"].find((row) => row._id == formVals.companySize)
			if (isSafe(codeListObj)) {
				companySize = codeListObj.name_ext
			}
		}

		let typOpravnenychAktivit = ""
		if (isNotEmpty(formVals.eligibleArea)) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.eligibleArea)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		}

		let printValues = {
			obchodne_meno: formVals.companyName,
			zoip: formVals.zoIPNumber,
			customFileName: formVals.zoIPNumber,
			pravnicka_osoba: pravnickaOsoba,
			adresa_ulica: formVals.companyStreet,
			adresa_popisne_cislo: formVals.companyStreetNumber,
			adresa_obec: adresaObec,
			adresa_psc: formVals.companyPSC,
			adresa_kraj: adresaKraj,
			koresp_adresa_ulica: isNotEmpty(formVals.companyCorrespondenceStreet)
				? formVals.companyCorrespondenceStreet
				: formVals.companyStreet,
			koresp_adresa_popisne_cislo: isNotEmpty(formVals.companyCorrespondenceStreetNumber)
				? formVals.companyCorrespondenceStreetNumber
				: formVals.companyStreetNumber,
			koresp_adresa_obec: isNotEmpty(adresaCorspondingObec) ? adresaCorspondingObec : adresaObec,
			koresp_adresa_psc: isNotEmpty(formVals.companyCorrespondencePSC)
				? formVals.companyCorrespondencePSC
				: formVals.companyPSC,
			pravna_forma: pravnaForma,
			ic_dph: formVals.icdph,
			ico: formVals.ico,
			dic: formVals.dic,
			iban: formVals.companyIBAN,
			kontakt_meno: formVals.contactPersonName,
			email: formVals.contactPersonMail,
			telefon: formVals.contactPersonPhone,
			project_name: formVals.projectName,
			typ_opravnenych_aktivit: typOpravnenychAktivit,
			strucny_popis_projektu: formVals.projectDescAndTarget,
			vychodiskova_situacia: formVals.projectBasicParameters,
			sposob_realizacie_projektu: formVals.projectImplementationMethod,
			doplnujuce_udaje: formVals.projectAdditionalInfo,
			potentialSolverName: formVals.potentialSolverName,
			potentialSolverIco: formVals.potentialSolverIco,
			potentialSolverDic: formVals.potentialSolverDic,
			potentialSolverIcdph: formVals.potentialSolverIcdph,
			potentialSolverCompletePrice: formVals.potentialSolverCompletePrice,
			potentialSolverVoucherPrice: formVals.potentialSolverVoucherPrice,
			potentialSolverCofinancedPrice: formVals.potentialSolverCofinancedPrice,
			sendDate: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : "",
			consent_marketing_text: formVals.sentMarketingDataYes == true ? "ÁNO súhlasím" : "NIE, nesúhlasím",
			miesto_realizacie_projektu: formVals.projectRealizationPlace,
			lehota_plnenia_projektu: formVals.projectDeadlineCompletion,
			velkost_podniku: companySize || "",
			opravnene_aktivity: formVals.legitimateActivities,
			jeden_podnik: formVals.oneCompanySubjectsIdentification,
			pomoc_ine_zdroje: formVals.otherSourcesSupportCheckboxYes ? "Áno" : "Nie"
		}

		let statutari_tr = ""
		//Statutari
		let statutarData = isEmpty(formVals.applicantChipForm) ? [] : JSON.parse(formVals.applicantChipForm)
		statutarData.map((statutar) => {
			statutari_tr += `
			<tr>
				<td class="td_80 bg_blueLight center">${statutar.name}
				<td class="td_20 bold center">${statutar.isStatutar ? "x" : ""}</td>
			</tr>`
		})

		printValues["statutarny_organ_ziadatela_table"] = `<table style="margin-top: 20px;">
			<tr>
				<td class="td_80 bg_blue bold blue center" style="font-size: 13px;">2. Štatutárny orgán Žiadateľa / Fyzická osoba Žiadateľa</td>
				<td class="td_20 bg_blue bold blue center" style="font-size: 13px;">Zmluvu IP podpíše</td>
			</tr>
			${statutari_tr}
		</table><div><br/></div>`

		printValues["oblast_poradenstva"] =
			formVals.ipceiAdvice1 == true
				? "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI - Vytvorenie dokumentu Project Portfolio podľa templatu, ktorý je definovaný EK a je súčasťou dokumentácie Výzvy IPCEI H2, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2020/ a Výzvy IPCEI mikrolektronika, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2021/ vrátane Funding Gap Questionnaire – template EK."
				: "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI – Vybrané jednotlivé kapitoly dokumentu Project Portfolio, alebo Funding Gap Questionnaire podľa výberu a potreby Žiadateľa."

		printReport("ipPC124", printValues)
	}

	@action generateExcel(form) {
		const formVals = form.values()

		let req = {
			file_name: "08_ZoIP_20210527.xlsx",
			data: [
				{
					key: "cislo_zoip",
					value: "123"
				},
				{
					key: "obchodne_meno",
					value: formVals.projectName
				},
				{
					key: "pravnicka_osoba",
					value: formVals.companyDesc
				},
				{
					key: "adresa_ulica",
					value: formVals.companyStreet
				},
				{
					key: "adresa_popisne_cislo",
					value: formVals.companyStreetNumber
				},
				{
					key: "adresa_obec",
					value: formVals.companyCity
				},
				{
					key: "adresa_psc",
					value: formVals.companyPSC
				},
				{
					key: "adresa_kraj",
					value: formVals.companyRegion
				},
				{
					key: "koresp_adresa_ulica",
					value: formVals.companyCorrespondenceStreet
				},
				{
					key: "koresp_adresa_popisne_cislo",
					value: formVals.companyCorrespondenceStreetNumber
				},
				{
					key: "koresp_adresa_obec",
					value: formVals.companyCorrespondenceCity
				},
				{
					key: "koresp_adresa_psc",
					value: formVals.companyCorrespondencePSC
				},
				{
					key: "pravna_forma",
					value: formVals.companyLegalForm
				},
				{
					key: "ic_dph",
					value: formVals.icdph
				},
				{
					key: "ico",
					value: formVals.ico
				},
				{
					key: "dic",
					value: formVals.dic
				},
				{
					key: "iban",
					value: formVals.companyIBAN
				},
				{
					key: "kontakt_meno",
					value: formVals.contactPersonName
				},
				{
					key: "email",
					value: formVals.contactPersonMail
				},
				{
					key: "telefon",
					value: formVals.contactPersonPhone
				}
			]
		}

		//Statutari
		let statutarData = isEmpty(formVals.applicantChipForm) ? [] : JSON.parse(formVals.applicantChipForm)
		statutarData.map((statutar, index) => {
			req["data"].push({
				key: "statutar" + index,
				value: statutar.name
			})
		})

		api
			.exportToXLS(req)
			.call()
			.then((res) => {
				if (res.ok == true) {
					let fileName = isSafe(res.headers.get("content-disposition"))
						? res.headers.get("content-disposition").split("filename=")[1]
						: "export"
					res.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})
	}
}

var singleton = new RequestPrintStore()
export default singleton

